<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"></loading>

    <div class="col-12 col-sm-12">
      <div class="row">
        <div class="col-12">
          <div class="card pt-3">
            <div class="card-body">
              <h5 class="mb-4 mt-3">Dados</h5>
              <div class="row form-view">
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.companyName') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.companyName }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.cnpj') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.cnpj }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.fone') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.fone }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.zipcode') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.zipcode }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.state') }}</label>
                  <p class="request-access-validation-data">{{ (requestAccess.state) ? requestAccess.state.nome : ''}}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.city') }}</label>
                  <p class="request-access-validation-data">{{ (requestAccess.city) ? requestAccess.city.nome : '' }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.firstName') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.firstName }}</p>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="active mb-1">{{ $t('request-access.lastName') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.lastName }}</p>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="active mb-1">{{ $t('request-access.email') }}</label>
                  <p class="request-access-validation-data">{{ requestAccess.email }}</p>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="active mb-1">{{ this.$i18n.t('form.request-access.fluxoAprovacao') }}</label>
                  <p class="request-access-validation-data">{{ approvalFlow }}</p>
                </div>
              </div>

              <form @submit.prevent="onSubmit('frmRequestAccess')" data-vv-scope="frmRequestAccess">
                <div class="col-md-12 mb-2">
                  <custom-textarea
                    v-model="internalInfo"
                    name="requestAccess.internalInfo"
                    :label="this.$i18n.t('request-access.internalInfo')"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    :rows="10"
                    v-validate="isRequired ? { required: true } : { required: false }"
                    background="#ffffff"
                    :error="errors.first('requestAccess.internalInfo')">
                  </custom-textarea>
                </div>
                <div class="col-12 col-md-6"  v-if="!requestAccess.comercialAccepted">
                  <label>
                    Aviso!<br/>
                    Ao habilitar esta função, estou ciente que o Tier (lucratividade) foram ajustados para este cliente e estão de acordo com a política comercial da Royal
                  </label>
                  <custom-switch
                    :listItems="[{text: 'Permitir Cotações Online', value: 1}]"
                    v-model="requestAccess.allowOnlineProposal"
                    name="requestAccess.allowOnlineProposal"
                    type="checkbox"
                    root-class-name=" col-12 mt-2 mb-6"
                    stateClassName="p-primary"
                    :error="errors.first('requestAccess.allowOnlineProposal')">
                  </custom-switch>
                </div>
                <!--    Actions Buttons  -->
                <div class="col-12 col-sm-12">
                  <div class="row pull-right">
                      <div class="col-12 p-0 pb-4">
                      <button class="btn btn-outline-light pull-right ml-1"
                        value="approve"
                        v-on:click="action = 'approve'">{{ $t('request-access.approve') }}</button>
                      <button class="btn btn-outline-light pull-right mr-1"
                        value="refuse"
                        v-on:click="action = 'refuse'">{{ $t('request-access.refuse') }}</button>
<!--                      <div class="pull-right vertical-center" v-if="!requestAccess.comercialAccepted" >-->
<!--                        <custom-switch-->
<!--                          :listItems="[{text: 'Permitir Cotações Online', value: 1}]"-->
<!--                          v-model="requestAccess.allowOnlineProposal"-->
<!--                          name="requestAccess.allowOnlineProposal"-->
<!--                          type="checkbox"-->
<!--                          root-class-name="text-right col-12 mt-2 mb-6"-->
<!--                          stateClassName="p-primary"-->
<!--                          :error="errors.first('requestAccess.allowOnlineProposal')">-->
<!--                        </custom-switch>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>
                <!--    Actions Buttons  -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import BaseService from '@/services/BaseService'
import AddressService from '@/services/AddressService'
import UserService from '@/services/UserService'

// Form components
import CustomTextarea from '@/components/Forms/CustomTextarea'
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

import '@/assets/css/custom.css'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'RequestAccessValidation',
  metaInfo: {
    titleTemplate: '%s | Solicitação de acesso'
  },
  components: {
    CustomTextarea,
    Loading,
    CustomSwitch
  },
  data () {
    return {
      isLoading: true,
      BaseURL: process.env.VUE_APP_BaseURL,
      stateList: [],
      cityList: [],
      approvalFlowList: [
        {
          id: 1,
          nome: this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_prospecto')
        },
        {
          id: 2,
          nome: this.$i18n.t('form.request-access.fluxoAprovacaoList.novo_contato_prospecto')
        },
        {
          id: 3,
          nome: this.$i18n.t('form.request-access.fluxoAprovacaoList.solicitacao_acesso')
        }
      ],
      token: null,
      action: null,
      approvalFlow: null,
      internalInfo: null,
      requestAccess: {
        companyName: null,
        cnpj: null,
        zipcode: null,
        fone: null,
        state: null,
        city: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
        accept: false,
        allowOnlineProposal: null,
        comercialAccepted: null
      },
      submitted: false,
      loading: false
    }
  },
  created () {
    let _this = this

    _this.token = _this.$route.params.token || null
    _this.action = _this.$route.params.action || null

    UserService.getAccessRequest(_this.token).then(res => {
      _this.requestAccess = res.data.data
      _this.internalInfo = _this.requestAccess.internalNote
      _this.approvalFlow = _this.approvalFlowList.find(flow => flow.id === res.data.data.approvalFlow).nome

      _this.isLoading = false
    })
  },
  methods: {
    accept (requestAccessData) {
      return new Promise((resolve, reject) => {
        let _this = this
        UserService.acceptAccessRequest(_this.token, requestAccessData)
          .then(function (res) {
            if (res) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    showTerms () {
    },
    onSelectState (val) {
      let _this = this
      _this.isLoading = true
      AddressService.getCities({ state: val.id, per_page: 500 }).then(res => {
        _this.cityList = res.data.data
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    selectFilter (option, label, search) {
      let searchTerm = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(searchTerm) > -1)
    },
    onCepInput (value) {
      let _this = this
      value = value.replace(/[^0-9]+/g, '')

      if (value && value.length >= 8) {
        _this.isLoading = true
        BaseService.getCEP(value).then(function (res) {
          let cepInfo = res.data.data
          if (cepInfo) {
            _this.isLoading = true
            let state = _this.stateList.find(e => e.sigla === (cepInfo.uf))

            if (state) {
              _this.requestAccess.state = state
              AddressService.getCities({ state: state.id, per_page: 500 }).then(res => {
                _this.cityList = res.data.data

                if (cepInfo.localidade) {
                  let city = _this.cityList.find(e => {
                    let localidade = cepInfo.localidade.toLowerCase()
                    localidade = localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                    let nome = e.nome.toLowerCase()
                    nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                    return (nome.indexOf(localidade) > -1)
                  })

                  if (city) {
                    _this.requestAccess.city = city
                  }
                }
                _this.isLoading = false
              }).catch(() => {
                _this.isLoading = false
              })
            }
          }
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      }
    },
    onSubmit (scopeName) {
      let _this = this
      _this.submitted = true

      _this.$validator.validateAll(scopeName).then((result) => {
        if (result) {
          let requestAccessData = {
            form: {
              message: _this.internalInfo
            }
          }

          if (_this.action !== 'refuse' && !_this.requestAccess.comercialAccepted) {
            requestAccessData.form.allowOnlineProposal = _this.requestAccess.allowOnlineProposal === 1
          }

          _this.isLoading = true

          if (_this.action === 'refuse') {
            UserService.refuseAccessRequest(_this.token, requestAccessData)
              .then(function (res) {
                if (res) {
                  let message = _this.$i18n.t('request-access.success_refused')
                  _this.isLoading = false
                  global.instanceApp.$alertSwal.modalAlert('', message, 'success', '', {
                    showCancelButton: false,
                    showConfirmButton: true,
                    showCloseButton: false
                  }).then((result) => {
                    _this.$router.push({ name: 'RequestAccessIndex' })
                  })
                }
              })
              .catch(() => {
                _this.isLoading = false
              })
          } else {
            _this.accept(requestAccessData).then(res => {
              _this.isLoading = false
              if (res) {
                let message = _this.$i18n.t('request-access.success_accepted')

                global.instanceApp.$alertSwal.modalAlert('', message, 'success', '', {
                  showCancelButton: false,
                  showConfirmButton: true,
                  showCloseButton: false
                }).then((result) => {
                  _this.$router.push({ name: 'RequestAccessIndex' })
                })
              }
            }).catch(() => {
              _this.isLoading = false
            })
          }
        }
      })
    },
    onSelectFlow (value) {
      this.approvalFlow = value
    }
  },
  computed: {
    isRequired () {
      if (this.action === 'refuse') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
.login-wrap .left-login form span {
  font-size: 0.95em;
}

.request-access-validation-data {
  color: grey;
  margin-bottom: 0px;
}

.approvalFlowOption {
  color:grey;
}
</style>
